<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon class="mr-1">
            {{ icons.back }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pb-8">
      <v-card-title>
        <h3 style="display: inline-block; margin-right: auto">
          Router Information
        </h3>
        <v-spacer></v-spacer>

        <v-col
          class="text-md-right"
          cols="12"
          md="3"
        >
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Actions
                <v-icon right>
                  {{ icons.menuDown }}
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="can('router-backup-create')"
                @click="showConfirmBackupDialog = true"
              >
                <v-list-item-title>Create a backup</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showDownloadHotspotConfiguredHtmlDialog = true">
                <v-list-item-title>Download hotspot configured html</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-btn
          color="primary"
          @click="showConfirmRebootDialog = true"
        >
          <v-icon left>
            {{ icons.restart }}
          </v-icon>
          Reboot
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-card class="details-card">
              <v-card-text>
                <h4>Model</h4>
                <template v-if="isLoadingModel && !model">
                  <v-progress-circular
                    indeterminate
                    size="20"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  <p>{{ model }}</p>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-card class="details-card">
              <v-card-text>
                <h4>Router Uptime</h4>
                <template v-if="isLoadingUptime && !routerUptime">
                  <v-progress-circular
                    indeterminate
                    size="20"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  <p>{{ routerUptime }}</p>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-card class="details-card">
              <v-card-text>
                <h4>CPU Usage</h4>
                <template v-if="isLoadingCpuUsage && !cpuUsage">
                  <v-progress-circular
                    indeterminate
                    size="20"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  <p>{{ formatPercentage(cpuUsage) }}</p>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-card class="details-card">
              <v-card-text>
                <h4>Free Memory</h4>
                <template v-if="isLoadingFreeMemory && !freeMemory">
                  <v-progress-circular
                    indeterminate
                    size="20"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  <p>{{ formatMemory(freeMemory) }}</p>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-8">
      <v-card-title>
        <v-col
          cols="12"
          md="6"
        >
          <h3>Router Backups</h3>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="text-md-right"
        >
          <v-btn
            v-if="can('router-backup-create')"
            color="primary"
            class="mb-2"
            @click="showConfirmBackupDialog = true"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            Create Backup
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <router-backups-table
          :backups-url="'router-backups?routerID=' + routerID"
          :router-id="routerID"
          :show-confirm-backup-dialog="showConfirmBackupDialog"
          @close-backup-dialog="showConfirmBackupDialog = false"
        />
      </v-card-text>
    </v-card>

    <v-card class="mt-8">
      <v-card-title>
        <v-col
          cols="12"
          md="10"
        >
          <h3>Router Logs</h3>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <refresh-button
            :loading="isRefreshingLogs"
            :tooltip="'Click to refresh logs'"
            @click="refreshLogs"
          />
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="logs"
          :loading="isLoadingLogs"
          class="elevation-1"
        >
          <template v-slot:item.topics="{ item }">
            <span :title="item.topics">{{ item.topics.split(',').join(', ') }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <confirm-dialog
      :show-dialog="showConfirmRebootDialog"
      :title="'Confirm Reboot'"
      :message="'Are you sure you want to reboot the router?'"
      :is-agree-button-loading="isConfirmRebootDialogLoading"
      :agree-text="'Reboot'"
      @agree="rebootRouter"
      @cancel="showConfirmRebootDialog = false"
    />
    <confirm-dialog
      :show-dialog="showDownloadHotspotConfiguredHtmlDialog"
      :title="'Confirm Download'"
      :message="'Download hotspot configured html?'"
      :is-agree-button-loading="isDownloadingHotspotConfiguredHtml"
      :agree-text="'Download'"
      @agree="downloadHotspotConfiguredHtml"
      @cancel="showDownloadHotspotConfiguredHtmlDialog = false"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import {
  mdiRestart, mdiArrowLeft, mdiMenuDown, mdiDownload, mdiDelete, mdiPlus,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import RouterBackupsTable from '@/components/tables/RouterBackupsTable.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    breadcrumb: BreadCrumb,
    ConfirmDialog,
    RefreshButton,
    RouterBackupsTable,
  },
  mixins: [
    hasPermission,
  ],
  data() {
    return {
      routerID: this.$route.params.id,
      routerUptime: '',
      cpuUsage: '',
      freeMemory: '',
      model: '',
      showConfirmRebootDialog: false,
      showDownloadHotspotConfiguredHtmlDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmBackupDialog: false,
      isConfirmRebootDialogLoading: false,
      isDownloadingHotspotConfiguredHtml: false,
      isLoadingUptime: false,
      isLoadingCpuUsage: false,
      isLoadingFreeMemory: false,
      isLoadingModel: false,
      isLoadingLogs: false,
      isLoadingBackups: false,
      isRefreshingLogs: false,
      isRefreshingBackups: false,
      isCpuUsageRequestPending: false,
      isFreeMemoryRequestPending: false,
      isUptimeRequestPending: false,
      isRscFileDownloading: false,
      isBackupFileDownloading: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBackupDialogButtonLoading: false,
      downloadingRscFiles: {},
      downloadingBackupFiles: {},
      cpuUsageTimer: null,
      freeMemoryTimer: null,
      uptimeTimer: null,
      logs: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Networking',
          disabled: true,
          to: { name: 'routers' },
        },
        {
          text: 'Routers',
          disabled: false,
          to: { name: 'routers' },
        },
        {
          text: this.$route.params.id,
          disabled: true,
          to: { name: 'routers' },
        },
      ],
      headers: [
        { text: 'Time', value: 'time' },
        { text: 'Topics', value: 'topics' },
        { text: 'Message', value: 'message' },
      ],
      backupHeaders: [
        { text: 'Date', value: 'date' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      backups: [],
      icons: {
        restart: mdiRestart,
        back: mdiArrowLeft,
        menuDown: mdiMenuDown,
        mdiDownload,
        delete: mdiDelete,
        mdiPlus,
      },
      toDelete: '',
    }
  },
  created() {
    this.fetchRouterInformation()
    this.fetchCpuUsage()
    this.fetchFreeMemory()
    this.fetchName()
    this.startCpuUsageTimer()
    this.startFreeMemoryTimer()
    this.startUptimeTimer()
    this.fetchLogs()
  },
  beforeDestroy() {
    this.stopCpuUsageTimer()
    this.stopFreeMemoryTimer()
    this.stopUptimeTimer()
  },
  methods: {
    fetchRouterInformation() {
      this.isLoadingUptime = true
      this.fetchRouterUptime()

      this.isLoadingModel = true
      this.fetchModel()
        .finally(() => {
          this.isLoadingModel = false
        })
    },
    fetchRouterUptime() {
      if (this.isUptimeRequestPending) return

      this.isUptimeRequestPending = true

      axios.get(`router/uptime/${this.routerID}`).then(response => {
        const uptimeDuration = response.data
        const durationRegex = /(\d+)([a-z]+)(?!s)/gi // Exclude 's' from matching seconds
        const unitsMap = {
          m: 'm',
          min: 'm',
          h: 'h',
          hr: 'h',
          d: 'd',
          day: 'd',
          w: 'w',
          wk: 'w',
          M: 'M',
          mon: 'M',
          y: 'y',
          yr: 'y',
        }

        let formattedUptime = ''

        uptimeDuration.replace(durationRegex, (_, value, unit) => {
          if (unitsMap[unit]) {
            formattedUptime += `${value}${unitsMap[unit]} `
          }
        })

        this.routerUptime = formattedUptime.trim()
      }).finally(() => {
        this.isLoadingUptime = false
        this.isUptimeRequestPending = false
      })
    },
    fetchCpuUsage() {
      if (this.isCpuUsageRequestPending) return

      this.isCpuUsageRequestPending = true
      this.isLoadingCpuUsage = true
      axios.get(`router/cpu-usage/${this.routerID}`).then(response => {
        this.cpuUsage = response.data
      }).finally(() => {
        this.isLoadingCpuUsage = false
        this.isCpuUsageRequestPending = false
      })
    },
    fetchFreeMemory() {
      if (this.isFreeMemoryRequestPending) return

      this.isFreeMemoryRequestPending = true
      this.isLoadingFreeMemory = true
      axios.get(`router/free-memory/${this.routerID}`).then(response => {
        this.freeMemory = response.data
      }).finally(() => {
        this.isLoadingFreeMemory = false
        this.isFreeMemoryRequestPending = false
      })
    },
    fetchModel() {
      return axios.get(`router/model/${this.routerID}`).then(response => {
        this.model = response.data
      })
    },
    fetchName() {
      return axios.get(`router/name/${this.routerID}`).then(response => {
        this.breadcrumbs.pop()
        this.breadcrumbs.push({
          text: response.data,
          disabled: true,
          to: { name: 'routers' },
        })
      })
    },
    rebootRouter() {
      this.isConfirmRebootDialogLoading = true
      axios.post(`router/reboot/${this.routerID}`).then(() => {
        this.$router.push({ name: 'routers' })
      }).then(() => {
        this.$toast.success('Router is rebooting')
      }).catch(() => {
        this.$toast.error('Failed to reboot router')
      })
        .finally(() => {
          this.isConfirmRebootDialogLoading = false
          this.showConfirmRebootDialog = false
        })
    },
    downloadHotspotConfiguredHtml() {
      this.isDownloadingHotspotConfiguredHtml = true
      axios.get(`router/download-hotspot-configured-html/${this.routerID}`).then(response => {
        const blob = new Blob([response.data], { type: 'text/html' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'login.html')
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.$toast.error('Failed to download hotspot configured html')
      }).finally(() => {
        this.isDownloadingHotspotConfiguredHtml = false
        this.showDownloadHotspotConfiguredHtmlDialog = false
      })
    },
    async fetchLogs() {
      try {
        this.isLoadingLogs = true

        // Fetch logs using routerID
        const response = await axios.get(`router/logs/${this.routerID}`)

        // Get current date as a fallback for logs without date information
        const currentDate = new Date().toISOString().split('T')[0]

        this.logs = response.data.map(log => {
          let dateTime = log.time

          // Check if the log's time field doesn't include a date
          if (!dateTime.includes('/')) {
            // Prepend the current date
            dateTime = `${currentDate} ${dateTime}`
          }

          // Convert the dateTime string to a timestamp for easy comparison
          const timestamp = new Date(dateTime).getTime()

          return { ...log, timestamp }
        })

        // Sort logs by timestamp in descending order
        this.logs.sort((a, b) => b.timestamp - a.timestamp)
      } catch (error) {
        console.error('Error fetching logs:', error)
      } finally {
        this.isLoadingLogs = false
      }
    },
    refreshLogs() {
      this.isRefreshingLogs = true
      this.fetchLogs()
        .finally(() => {
          this.isRefreshingLogs = false
        })
    },
    test() {
      console.log('test')
    },
    formatPercentage(value) {
      return `${value}%`
    },
    formatMemory(value) {
      // Convert bytes to megabytes
      const megabytes = value / (1020 * 1020)

      return `${megabytes.toFixed(2)} MB`
    },
    startCpuUsageTimer() {
      this.cpuUsageTimer = setInterval(this.fetchCpuUsage, 3000)
    },
    stopCpuUsageTimer() {
      clearInterval(this.cpuUsageTimer)
    },
    startFreeMemoryTimer() {
      this.freeMemoryTimer = setInterval(this.fetchFreeMemory, 8000)
    },
    stopFreeMemoryTimer() {
      clearInterval(this.freeMemoryTimer)
    },
    startUptimeTimer() {
      this.fetchRouterUptime()
      this.uptimeTimer = setInterval(this.fetchRouterUptime, 60000)
    },
    stopUptimeTimer() {
      clearInterval(this.uptimeTimer)
    },
  },
}
</script>

<style scoped>
.details-card {
  min-height: 98px;
}
</style>
